.team-container-outer {
    flex: 1;
}

.team-container-inner {
    padding: 50px 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 70px;
}

.team-header {
    font-size: 40px;
    font-weight: 600;
}

.team-list {
    flex: 1;
    width: 100%;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    align-content: flex-start;
    gap: 50px;
    flex-wrap: wrap;
}
