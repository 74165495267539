#about-container {
  /* border: 1px solid red; */
  /* min-height: 99.9vh; */
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 50px;
  color: white;
  /* margin-bottom: 10vh; */
  /* overflow: scroll; */
}

#info-container {
  margin: 0 auto;
  margin-top: 12vh;
  /* width: 70%; */
  width: 1200px;
  max-width: 60%;
  /* height: 100%; */
  color: white;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

#about-header {
  font-size: 7vh;
}

.bold {
  font-weight: 600;
}

.about-paragraph {
  font-size: 2.6vh;
}

#team-container {
  /* width: 70%; */
  width: 1200px;
  max-width: 60%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 50px;
  /* border: 1px solid red; */
}

#team-header {
  font-size: 7vh;
  font-weight: 500;
}

#team-members {
  display: flex;
  gap: 20px;
  /* border: 1px solid red; */
  /* justify-content: space-between; */
  flex-wrap: wrap;
  margin-bottom: 5vh;
}

@media only screen and (max-width: 900px) {
  #info-container,
  #team-container {
    max-width: 80%;
  }

  #team-members {
    justify-content: center;
  }
}
