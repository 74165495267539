#contact-container {
  /* border: 1px solid red; */
  /* height: 99.9vh; */
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  display: flex;
  flex-direction: column;
  font-size: 50px;
  font-weight: 500;
  gap: 10px;
}

#inq {
  font-weight: 400;
  font-size: 40px;
}

#email {
  color: white;
  text-decoration: none;
}

#email:hover {
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  #contact-container {
    font-size: 30px;
  }

  #inq {
    font-size: 28px;
  }
}
