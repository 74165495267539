#home-container {
  /* min-height: 99.9vh;
  max-height: 99.9vh; */
  height: 100%;
  overflow-y: hidden;
  /* width: fit-content; */
  color: white;
  /* scroll-snap-type: x mandatory; */
  /* scroll-snap-align: repeat(100vw); */
  /* overflow-x: scroll; */
  display: flex;
  flex-direction: column;
  scroll-behavior: smooth;
  overflow: hidden;
  /* cursor: grab; */

  /* TEMP FIX */
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  font-size: 40px;
  /* gap: 10px; */
  /* text-decoration: underline; */
}

.home-container.drag {
  scroll-snap-type: none;
  /* cursor: grabbing; */
}

#navbar {
  /* border: 1px solid red; */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 2vw 4vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(
    var(--main-color) 10%,
    #202125d9 70%,
    transparent
  );
  z-index: 2;
  margin-right: 15px;
  /* background: var(--main-color); */
}

/* #reel-container {
    width: fit-content;
    height: 100%;
    display: flex;
    -ms-overflow-style: none;
    scrollbar-width: none;
    scroll-snap-type: x mandatory;
} */

.home-container::-webkit-scrollbar {
  display: none;
}

.video-container {
  scroll-snap-align: start;
  /* border: 1px solid red; */
  height: 100%;
  display: flex;
  width: 100vw;
  max-width: 100vw;
  min-width: 100vw;
}

.video-pillar {
  background-color: var(--secondary-color);
  flex: 0.5;
}

.video-window {
  /* background-color: blue; */
  flex: 9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.video-player {
  /* background-color: var(--secondary-color); */
  width: 60%;
  max-height: 80vh;
  aspect-ratio: 16/9;
}

.video {
  height: 100%;
  width: 100%;
}

.video-info {
  /* position: absolute; */
  bottom: 3rem;
  left: 4rem;
  font-size: 20px;
  font-weight: 300;
  display: flex;
  flex-direction: column;
  gap: 4px;
  /* border: 1px solid red; */
}

.video-controls {
  /* position: absolute; */
  bottom: 3rem;
  right: 4rem;
  font-size: 20px;
  font-weight: 300;
  display: flex;
  flex-direction: row;
  gap: 20px;
  /* border: 1px solid red; */
}

.video-controls button {
  background: none;
  border: none;
  outline: none;
  padding: 0;
  display: inline-block;
  position: relative;
}

.video-controls > button::before {
  content: "";
  /* position: absolute; */
  /* border: 1px solid red; */
  position: absolute;
  height: 100%;
  width: 100%;
  top: -3px;
  left: 0;
  background: #2021254d;
  z-index: 2;
  opacity: 1;
  transition: 0.3s opacity ease;
}

.video-controls button:hover::before {
  opacity: 0;
}

.video-controls img {
  height: 15px;
  cursor: pointer;
}

.video-controls img:first-of-type {
  height: 17px;
  position: relative;
  bottom: 1px;
}

.info-bold {
  font-weight: 600;
}

#slideNumber {
  width: 30px;
  min-width: 30px;
  max-width: 30px;
  text-align: right;
  font-weight: 400;
  position: relative;
  bottom: 0.22rem;
}

#bottomControls {
  /* border: 1px solid red; */
  /* height: 100px; */
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  padding: 2rem 4rem;
  /* width: 100vw;
  width: 100px; */
  align-items: center;
}

#slideSelector {
  display: flex;
  gap: 15px;
  /* border: 1px solid red; */
}

#slideSelector img {
  height: 50px;
  opacity: 40%;
  transition: 0.3s opacity ease;
  cursor: pointer;
}

#slideSelector img.selected {
  opacity: 100%;
}

#slideSelector img:hover {
  opacity: 100%;
}

.video-info,
.video-controls {
  width: 100px;
  overflow-x: visible;
  white-space: nowrap;
}

@media only screen and (max-width: 900px) {
  #info-container,
  #team-container {
    max-width: 80%;
  }

  #team-members {
    justify-content: center;
  }
}

@media only screen and (max-width: 900px) {
  .video-player {
    width: 90%;
  }

  #slideSelector {
    display: none;
  }

  #bottomControls {
    padding: 2vh 4vw;
  }
}

@media only screen and (max-width: 500px) {
  .video-controls {
    margin-right: 10px;
  }
}
