.launch-container {
  /* height: 99.9vh;
    max-height: 99.9vh; */
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  z-index: 10;
  background-color: var(--main-color);
  position: absolute;
  /* border: 1px solid red; */
}

#launch-text {
  font-weight: 600;
  font-size: 70px;
  color: white;
  z-index: 2;
  opacity: 0;
}

#launch-img {
  height: 110px;
  position: absolute;
  /* box-shadow: 0 0 60px 10px #fff, 0 0 100px 35px rgb(255, 0, 0),
        0 0 100px 50px rgb(0, 132, 255); */
  /* box-shadow: 0 0 60px 30px #fff, 0 0 100px 60px rgb(179, 255, 0),
        0 0 180px 90px rgb(153, 0, 255); */
  /* opacity: 0; */

  opacity: 0;
}
