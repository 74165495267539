@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@300;400;500;600;700&display=swap");

/* comment */
/*  */
html,
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --main-font: "Barlow", sans-serif;
  --main-color: #202125;
  --secondary-color: #111214;
  font-family: var(--main-font);
  background-color: var(--main-color);
  overflow-y: overlay;
  height: 100%;
  overflow-x: hidden;
}

#root {
  height: 100%;
}

button {
  font-family: var(--main-font);
  font-family: var(--main-font);
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 20px;
}

/* Track */
::-webkit-scrollbar-track {
  background: inherit;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(14, 14, 14);
  border: 6px solid rgba(107, 107, 107, 0);
  border-radius: 20px;
  background-clip: padding-box;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(7, 7, 7);
  border: 6px solid rgba(27, 27, 27, 0);
  border-radius: 20px;
  background-clip: padding-box;
}