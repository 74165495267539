.membercard-container {
  background-color: var(--secondary-color);
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  height: fit-content;
  width: 260px;
  min-width: 260px;
  height: 360px;
  text-align: center;
  padding: 30px;
  box-sizing: border-box;
  border-radius: 20px;
}

.membercard-img {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  object-fit: cover;
}

.member-main-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 15px;
  color: rgb(197, 197, 197);
  /* flex: 1; */
}

.member-name {
  font-size: 22px;
  font-weight: 600;
  color: white;
}

.member-secondary-info {
  font-size: 13px;
  overflow-y: auto;
}

.member-secondary-info::-webkit-scrollbar {
  width: 18px;
}

.member-secondary-info::-webkit-scrollbar-thumb {
  background: rgb(80, 80, 80);
  /* border: 6px solid rgba(107, 107, 107, 0); */
  border-radius: 10px;
  background-clip: padding-box;
}

.member-secondary-info::-webkit-scrollbar-thumb:hover {
  background: rgb(60, 60, 60);
  border: 6px solid rgba(27, 27, 27, 0);
  border-radius: 20px;
  background-clip: padding-box;
}
