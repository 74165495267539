#main-container {
  /* min-height: 99.9vh; */
  /* min-height: 200vh; */
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  /* border: 1px solid red; */
  /* align-items: center; */
}

#main-logo {
  color: white;
  font-size: 40px;
  font-weight: 600;
}

#links-right {
  display: flex;
  gap: 2vw;
  font-size: 18px;
}

.link {
  text-decoration: none;
  color: rgb(194, 194, 194);
  transition: color 0.3s;
}

.link:hover {
  color: white;
}

.white {
  display: flex;
  justify-content: center;
  background-color: white;
}

.grey {
  display: flex;
  justify-content: center;
  background-color: rgb(224, 224, 224);
}

.restrict {
  max-width: 1250px;
  width: 100%;
}

.navbar-container-inner {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-left {
  display: flex;
  gap: 40px;
}

.navbar-logo {
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 18px;
  font-weight: 600;
  color: rgb(71, 71, 71);
}

#main-img {
  height: 30px;
  opacity: 1;
  transition: 0.3s;
  position: relative;
  z-index: 2;
}

#main-logo::after {
  content: "";
  background-image: url("../Images/colortext.png");
  background-size: 96px 30px;
  background-repeat: no-repeat;
  background-position: center;
  /* border: 1px solid red; */
  position: absolute;
  top: 2vw;
  left: 4vw;
  right: 0;
  height: 48px;
  width: 98px;
}

#main-img-secondary {
  height: 30px;
  position: relative;
  left: -98px;
  z-index: 1;
}

#main-img:hover {
  opacity: 0;
}

.nav-links-list {
  display: flex;
  gap: 40px;
  list-style: none;
  text-align: center;
}

.nav-links {
  text-decoration: none;
  color: rgb(71, 71, 71);
  transition: color 0.3s;
}

.nav-links:hover {
  color: rgb(0, 0, 0);
}

.navbar-logo img {
  height: 35px;
  border-radius: 10px;
}

.navbar-btn {
  border: none;
  outline: none;
  padding: 10px;
  color: white;
  background-color: #043740;
  border-radius: 10px;
  font-weight: 500;
}

.footer-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

#hamburger-links {
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  display: none;
  position: relative;
}

#hamburger-links img {
  padding-top: 5px;
  height: 30px;
}

#hamburger-links::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #2021254d;
  opacity: 1;
  transition: 0.3s opacity ease;
}

#hamburger-links:hover:after {
  opacity: 0;
}

#sideMenu {
  background-color: #202125;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 280px;
  transform: translateX(280px);
  z-index: 3;
  transition: 0.3s transform ease;
  /* display: none; */
}

#sideMenu.show {
  transform: translateX(0);
}

#overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #40424c94;
  z-index: 3;
  display: none;
}

#overlay.show {
  display: block;
}

#sideMenu {
  display: flex;
  flex-direction: column;
  gap: 30px;
  box-sizing: border-box;
  padding: 30px;
  color: white;
}

#sideMenu button {
  background: unset;
  border: none;
  outline: none;
  align-self: flex-end;
  color: white;
  position: relative;
}

#sideMenu button img {
  height: 15px;
  width: 15px;
}

#sideMenu button::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 1;
  background-color: #2021254d;
  transition: 0.3s opacity ease;
}

#sideMenu button:hover::after {
  opacity: 0;
}

#sidemenuNav {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

#sidemenuNav a {
  font-size: 1.5rem;
  font-weight: 500;
}

@media only screen and (max-width: 500px) {
  #links-right {
    display: none;
  }

  #hamburger-links {
    display: block;
  }
}
